import { Types } from '../Constants/Types';
import TransactionType from '../Interfaces/transaction.interface';

const TransactionsReducer = (
    state :TransactionType['status']= {
        transaction: undefined,
        transactions: [],
        pages: 0,
        total: 0,
        ltransactions: false,
        ltransaction: false
    },
    action : TransactionType['action']) => {
    switch (action.type) {

        case Types.FETCH_TRANSACTIONS:
            return {...state, transactions: action.payload, pages: action.pages, total: action.total};

        case Types.FETCH_TRANSACTION:
            return {...state, transaction: action.payload};

        case Types.UPDATE_WALLET:
            if(action.payload.type === "Amount"){
                return {...state, transactions: [action.payload, ...state.transactions]};
            } else {
                return {...state, transactions: [action.payload, ...state.transactions]};
            }

        case Types.UPDATE_TRANSACTION: 
            return {...state, transactions: state.transactions.map(transaction => transaction._id === action.payload.id ? {...transaction, ...action.payload} : transaction)};
        
        case Types.STATE_TRANSACTION: 
        return {...state, transactions: state.transactions.map(transaction => transaction._id === action.payload.id ? {...transaction, ...action.payload} : transaction)};

        case Types.DELETE_TRANSACTION: 
            return {...state, transactions: state.transactions.filter(transaction => transaction._id !== action.payload)}

        case Types.START_TRANSACTIONS:
            return {...state, ltransactions: true};

        case Types.END_TRANSACTIONS: 
            return {...state, ltransactions: false};

        case Types.START_TRANSACTION:
            return {...state, ltransaction: true};

        case Types.END_TRANSACTION: 
            return {...state, ltransaction: false};

        default: return state
    }
}

export default TransactionsReducer;