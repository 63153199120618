
export enum AppRoutes {

    // Auth...
    register = "/register",
    login = "/login",
    resetPassword = "/resetpassword",
    createNewPassword = "/createNewPassword",

    home = "/",
    profile = "/profile",

    // Sections...
    sections = "/sections",
    section = "/sections/:id",
    createStoreSection = "/sections/create",
    sectionParams = "/sections/",

    // Orders...
    orders = "/orders",
    order = "/orders/:id",
    createOrder = "/orders/create",
    orderParams = "/orders/",

    // New Orders...
    // newOrders = "/new-orders",
    // newOrder = "/new-orders/:id",
    // createNewOrder = "/new-orders/create",
    // newOrderParams = "/new-orders/",

    // Orders...
    deposits = "/deposits",
    deposit = "/deposits/:id",
    createDeposit = "/deposits/create",
    depositParams = "/deposits/",
    
    // Scraping Items...
    scrapingItems = "/scraping-items",
    scrapingItem = "/scraping-items/:id",
    createScrapingItem = "/scraping-items/create",
    scrapingItemParams = "/scraping-items/",

    // Products...
    products = "/products",
    product = "/products/:id",
    createProduct = "/products/create",
    productParams = "/products/",

    // Outfits...
    outfits = "/outfits",
    outfit = "/outfits/:id",
    createOutfit = "/outfits/create",
    outfitParams = "/outfits/",

    // Categories...
    categories = "/categories",
    category = "/categories/:id",
    createCategory = "/categories/create",
    categoryParams = "/categories/",

    // Groups...
    groups = "/groups",
    group = "/groups/:id",
    createGroup = "/groups/create",
    groupParams = "/groups/",

    // Customers...
    customers = "/customers",
    customer = "/customers/:id",
    createCustomer = "/customers/create",
    customerParams = "/customers/",
    customerCart = "/customers/:id/customer-cart",

    // Supports...
    supports = "/supports",
    support = "/supports/:id",
    supportParams = "/supports/",

    // Transactions...
    transactions = "/transactions",
    transaction = "/transactions/:id",
    transactionParams = "/transactions/",

    // Driver Transactions 
    driverTransactions = "/driver-transactions",
    driverTransaction = "/driver-transactions/:id",
    driverTransactionParams = "/driver-transactions/",

    // Reports...
    reports = "/reports",
    report = "/reports/:id",
    reportParams = "/reports/",
    

    // Advertisements...
    advertisements = "/advertisements",
    createAd = "/advertisements/create",
    advertisement = "/advertisements/:id",
    advertisementParams = "/advertisements/",

    // Offers...
    offers = "/offers",
    discounts = "/offers/discounts",
    discount = "/offers/discounts/:id",
    createDiscount = "/offers/discounts/create",
    discountParams = "/offers/discounts/",

    paymentMethodDiscounts = "/offers/payment-method-discounts",
    paymentMethodDiscount = "/offers/payment-method-discount/:id",
    createPaymentMethodDiscount = "/offers/payment-method-discount/create",
    paymentMethodDiscountParams = "/offers/payment-method-discount/",

    promoCodes = "/offers/promo-codes",
    promoCode = "/offers/promo-codes/:id",
    createPromoCode = "/offers/promo-codes/create",
    promoCodeParams = "/offers/promo-codes/",

    cartOffers = "/offers/cart-offers",
    cartOffer = "/offers/cart-offers/:id",
    createCartOffer = "/offers/cart-offers/create",
    cartOfferParams = "/offers/cart-offers/",

    // Notifications...
    notifications = "/notifications",
    createNotification = "/notifications/create",
    notification = "/notifications/:id",
    notificationParams = "/notifications/",

    // Settings...
    settings = "/settings",
    socialLinks = "/settings/sociallinks",
    security = "/settings/security",
    // Payment Methods...
        paymentMethods = "/settings/paymentMethods",
        createPaymentMethod = "/settings/paymentMethods/create",
        paymentMethod = "/settings/paymentMethods/:id",
        paymentMethodParams = "/settings/paymentMethods/",
    // Authirizations...
        authorizations = "/settings/authorizations",
        createAuthorization = "/settings/authorizations/create",
        authorization = "/settings/authorizations/:id",
        authorizationParams = "/settings/authorizations/",
    // Drivers...
        drivers = "/settings/drivers",
        createDriver = "/settings/drivers/create",
        driver = "/settings/drivers/:id",
        driverParams = "/settings/drivers/",
    // Teams...
        teams = "/settings/teams",
        createTeam = "/settings/teams/create",
        team = "/settings/teams/:id",
        teamParams = "/settings/teams/",
}

