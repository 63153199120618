import { initialErrors } from "../Constants/Customers.constants";
import { Types } from "../Constants/Types";
import CustomerType from "../Interfaces/customer-interface";

const CustomersReducer = (state: CustomerType['status'] = { 
        customer: undefined,
        numbers: {total: 0, active: 0, new: 0},
        customers: [],
        orders: [],
        results: [],
        lcustomers: false,
        lorders: false,
        lcustomer: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: CustomerType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_CUSTOMERS:
            return {...state, results: action.payload, resultPages: action.pages, total: action.total};

        case Types.FETCH_CUSTOMERS:
            return {...state, customers: action.payload, pages: action.pages, total: action.total};

        case Types.FETCH_CUSTOMER_ORDERS:
            return {...state, orders: action.payload, pages: action.pages};

        case Types.FETCH_CUSTOMER:
            return {...state, customer: action.payload};

        case Types.NUMBERS_CUSTOMERS:
            return {...state, numbers: action.payload};

        case Types.CREATE_CUSTOMER:
            return {...state, customers: [action.payload, ...state.customers]};

        case Types.UPDATE_CUSTOMER: 
            return {...state, customers: state.customers.map(customer => customer._id === action.payload.id ? action.payload : customer)};
        
        case Types.STATE_CUSTOMER: 
            return {...state, customers: state.customers.map(customer => customer._id === action.payload.id ? {...customer, ...action.payload} : customer), customer: {...state.customer, state: action.payload.state}};

        case Types.DELETE_CUSTOMER: 
            return {...state, customers: state.customers.filter(customer => customer._id !== action.payload)}

        case Types.UPDATE_WALLET:
            if(action.payload.type === "Amount"){
                return {...state, customer: {...state.customer, wallet: {...state?.customer?.wallet, amount: action.payload.previous + action.payload.amount}}};
            } else {
                return {...state, customer: {...state.customer, wallet: {...state?.customer?.wallet, points: action.payload.previous + action.payload.amount}}};
            }

        case Types.START_CUSTOMERS:
            return {...state, lcustomers: true};

        case Types.END_CUSTOMERS:
            return {...state, lcustomers: false};

        case Types.START_CUSTOMER_ORDERS:
            return {...state, lorders: true};

        case Types.END_CUSTOMER_ORDERS:
            return {...state, lorders: false};

        case Types.START_CUSTOMER:
            return {...state, lcustomer: true};

        case Types.END_CUSTOMER:
            return {...state, lcustomer: false};

        case Types.START_WALLET:
            return {...state, lwallet: true};

        case Types.END_WALLET: 
            return {...state, lwallet: false};

        case Types.ERROR_CUSTOMER:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_CUSTOMER:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default CustomersReducer;